import React from 'react';

import { Handle, type NodeProps, Position } from 'reactflow';

import { Box, createStyles, Icon, lighten, TFText, ThemeIcon, Tooltip, UnstyledButton } from '@tf/ui';
import { S } from '@tf/utils';

import { NODE_HEIGHT, NODE_WIDTH, type StructureNodeData } from './utils';

const useStyles = createStyles(({ colors }) => ({
	account: {
		backgroundColor: colors.orange[2],
		borderColor: colors.orange[4],
		color: colors.orange[8],
		'&:hover': {
			backgroundColor: colors.orange[3],
			borderColor: colors.orange[5],
		},
	},

	person: {
		backgroundColor: colors.green[2],
		borderColor: colors.green[4],
		color: colors.green[8],
		'&:hover': {
			backgroundColor: colors.green[3],
			borderColor: colors.green[5],
		},
		'&[data-selected="true"]': {
			backgroundColor: colors.green[2],
			borderWidth: 3,
		},
	},

	company: {
		backgroundColor: colors.brand[1],
		borderColor: colors.brand[3],
		color: colors.brand[6],
		'&:hover': {
			backgroundColor: lighten(colors.brand[1], -0.2),
			borderColor: colors.brand[4],
		},
		'&[data-selected="true"]': {
			backgroundColor: lighten(colors.brand[1], -0.3),
			borderWidth: 3,
		},
	},

	content: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: '.5rem',
	},

	badge: {
		padding: '2px 4px',
		fontSize: '0.75rem',
		fontWeight: 500,
		color: colors.green[0],
		backgroundColor: colors.green[8],
		borderRadius: '999px',
	},
}));

const NodeIcon: React.FC<{ type: string; kind: string }> = ({ type }) => {
	if (type === 'account') {
		return (
			<ThemeIcon variant="light" c="orange" radius="xl">
				<Icon.IconAtom2 size={18} />
			</ThemeIcon>
		);
	}

	if (type === 'person') {
		return (
			<ThemeIcon variant="light" c="green" radius="xl">
				<Icon.IconUser size={18} />
			</ThemeIcon>
		);
	}

	if (type === 'company') {
		return (
			<ThemeIcon variant="light" c="brand" radius="xl">
				<Icon.IconBuildingSkyscraper size={18} />
			</ThemeIcon>
		);
	}

	return null;
};

/**
 * Custom entity node
 * @param data
 * @param type
 * @param isConnectable
 * @constructor
 */
export const EntityNode: React.FC<NodeProps<StructureNodeData>> = ({ data, type }) => {
	const { classes } = useStyles();

	let className: string;
	let kindLabel: string;
	switch (type) {
		case 'account':
			className = classes.account;
			kindLabel = S.prettify(data.kind);
			break;
		case 'person':
			className = classes.person;
			kindLabel = 'Person';
			break;
		default:
			className = classes.company;
			kindLabel = S.prettify(data.kind);
	}

	return (
		<div style={{ position: 'relative' }}>
			<Box
				sx={{
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
					transition: 'background-color .2s ease-in-out',
					borderWidth: 1,
					borderStyle: 'solid',
				}}
				className={className}
				data-selected={data.isSelected}
				w={NODE_WIDTH}
				h={NODE_HEIGHT}
			>
				<Box
					className="drag-handle"
					sx={{
						margin: '0 .5rem',
						cursor: 'move',
					}}
				>
					<NodeIcon type={type} kind={data.kind} />
				</Box>
				<UnstyledButton className={classes.content}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
						<TFText
							size="md"
							color="black"
							fw={500}
							lineClamp={1}
							sx={{
								maxHeight: '1.5rem',
								overflow: 'hidden',
							}}
						>
							{data.label}
						</TFText>
						{data.badge ? <Box className={classes.badge}>{data.badge}</Box> : null}
					</Box>
					<TFText size="md" c="dimmed" lineClamp={1}>
						{kindLabel}
					</TFText>
				</UnstyledButton>
			</Box>

			{data.tasks.length > 0 && (
				<Tooltip
					p="0 1rem"
					withinPortal
					position="top"
					styles={{
						tooltip: {
							zIndex: 9999,
						},
					}}
					label={
						<ul style={{ paddingLeft: '1rem' }}>
							{data.tasks.map((task) => (
								<li key={task.id}>{task.label}</li>
							))}
						</ul>
					}
				>
					<UnstyledButton
						onClick={(e) => {
							e.stopPropagation();
						}}
						sx={({ colors }) => ({
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							fontSize: '.7rem',
							fontWeight: 500,
							borderRadius: '50%',
							position: 'absolute',
							top: -12,
							right: -12,

							width: 24,
							height: 24,
							color: 'white',

							backgroundColor: colors.red[7],
							'&:hover': {
								backgroundColor: colors.red[8],
							},
						})}
					>
						{data.tasks.length}
					</UnstyledButton>
				</Tooltip>
			)}

			{/* Required to draw edges */}
			<Handle type="source" style={{ display: 'none' }} position={Position.Bottom} isConnectable />
			<Handle type="target" style={{ display: 'none' }} position={Position.Top} isConnectable />
		</div>
	);
};
