import type { BackendTypes } from '@tf/api';

export const getShareholderOwnershipInfo = (
	connectionKind: string,
	reviewData: BackendTypes.ReviewData,
	graphLinkId: number
) => {
	if (connectionKind !== 'SHAREHOLDER' && connectionKind !== 'ACCOUNT_HOLDER') {
		return undefined;
	}

	return reviewData.listShareholdersOwnershipInfo.find((s) => s.EntityConnectionId === graphLinkId);
};
