import React from 'react';

import { ModalsProvider } from '@mantine/modals';
import type { ModalSettings } from '@mantine/modals/lib/context';
import { Notifications } from '@mantine/notifications';

import ThemeProvider from './ThemeProvider';

const modalProps: ModalSettings = {
	padding: 0,
	styles: { header: { display: 'none' } },
};

const DGLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ThemeProvider>
			<Notifications limit={3} position="top-right" notificationMaxHeight={50} />
			<ModalsProvider modalProps={modalProps}>{children}</ModalsProvider>
		</ThemeProvider>
	);
};

export default DGLayout;
